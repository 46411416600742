interface CardProps {
  url: string;
  title: String;
  description: string;
  tags: any;
  RepositoryButton?: {};
  DemoButton?: {};
}

export default function Card({url,title,description,tags,RepositoryButton,DemoButton}:CardProps) {

return (
  <div class="card m-4">
    <img
      class="w-[90%] m-4 h-48 object-fill"
      src={`${url}`}
      alt="Project Card"
    />
    <div class="p-4 md:p-6">
      <h2 class="text-2xl font-bold mb-2 text-gray-700  ">{title}</h2>
      <p class="text-gray-700 mb-4">{description}</p>
      <div className="flex flex-wrap text-s">
        {tags &&
          tags.map((key, index) => (
            <div class="mb-4">
              <span class="card-tag text-gray-500  dark:text-white">{key}</span>
            </div>
          ))}
      </div>
      <div class="flex justify-start flex-wrap ">
        {RepositoryButton &&
          RepositoryButton.map((Repo, index) => (
            <a
              href={Repo.url}
              rel="noreferrer"
              target="_blank"
              class="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors m-1"
            >
              {Repo.title}
            </a>
          ))}

        {DemoButton &&
          DemoButton.map((Demo, index) => (
            <a
              rel="noreferrer"
              href={Demo.url}
              target="_blank"
              class="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition-colors m-1"
            >
              {Demo.title}
            </a>
          ))}
      </div>
    </div>
  </div>
);



}