import React, { useState, useEffect } from 'react';
import { Link, useLocation  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(true);
  const location = useLocation();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    if (darkMode) {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    } else {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    }
  };

  useEffect(() => {
    document.documentElement.classList.add('dark');
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <header className=" text-black  bg-white dark:text-white dark:bg-gray-900  py-4 relative md:px-6 border-b-2 ">
      <div className="container mx-auto flex justify-between items-center">
      <Link
    
            to="/"
            onClick={() => setIsOpen(false)}
          >
                  <img
          class="md:h-16 md:w-16 h-12 w-12 mx-2 object-cover"
          src="./images/logo.png"
          alt="Logo"
        />
          </Link>


  

        {/* Dark Mode Toggle */}
        <div className="flex items-center space-x-4 m-4">
          {/* Hamburger icon */}
          <div className="md:hidden z-20 cursor-pointer" onClick={toggleMenu}>
            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="lg" />
          </div>
        </div>

        {/* Navigation Links */}
        <nav
          className={`fixed top-0 left-0 w-full h-full  text-black bg-white   dark:text-white dark:bg-gray-900  opacity-95 dark:  flex flex-col items-center justify-center space-y-6 transform ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out md:relative md:flex-row md:translate-x-0 md:space-y-0 md:space-x-6 md:h-auto md:bg-transparent md:opacity-100 md:transform-none`}
        >
          <Link
            className={`text-lg font-medium p-2 rounded ${
              location.pathname === "/"
              ? "dark:border-b-white border-b-black border-b-4 border-b-solid"
                : "dark:text-white hover:bg-gray-700 hover:text-white"
            }`}
            to="/"
            onClick={() => setIsOpen(false)}
          >
            Home
          </Link>
          <Link
            className={`text-lg font-medium  p-2 rounded ${
              location.pathname === "/education"
                ? "dark:border-b-white border-b-black border-b-4 border-b-solid"
                : "dark:text-white hover:bg-gray-700 hover:text-white"
            }`}
            to="/education"
            onClick={() => setIsOpen(false)}
          >
            Education
          </Link>
          <Link
            className={` text-lg font-medium  p-2 rounded ${
              location.pathname === "/skills"
                ? "dark:border-b-white border-b-black border-b-4 border-b-solid"
                : "dark:text-white hover:bg-gray-700 hover:text-white"
            }`}
            to="/skills"
            onClick={() => setIsOpen(false)}
          >
            Skills
          </Link>
          <Link
            className={`text-lg font-medium  p-2 rounded ${
              location.pathname === "/projects"
                   ? "dark:border-b-white border-b-black border-b-4 border-b-solid"
                : "dark:text-white hover:bg-gray-700 hover:text-white"
            }`}
            to="/projects"
            onClick={() => setIsOpen(false)}
          >
            Projects
          </Link>
          <Link
            className={`text-lg font-medium  p-2 rounded ${
              location.pathname === "/contact"
               ? "dark:border-b-white border-b-black border-b-4 border-b-solid"
                : "dark:text-white hover:bg-gray-700 hover:text-white"
            }`}
            to="/contact"
            onClick={() => setIsOpen(false)}
          >
            Contact
          </Link>

          <button
            onClick={toggleDarkMode}
            className="dark:text-white  p-2 focus:outline-none"
          >
            <FontAwesomeIcon icon={darkMode ? faSun : faMoon} size="lg" />
          </button>
        </nav>
      </div>
    </header>
  );
}

export default Header;
