import React, { useState } from 'react';
import { motion } from 'framer-motion';

const ContactPage = () => {
  const [showPhone, setShowPhone] = useState(null);
  const [showEmail, setShowEmail] = useState(null);

  const contacts = [
    {
      name: 'Jarmo Krohns - Operation Director & HR',
      phone: ['050', '569', '6043'],
      email: ['jarmo.krohns', '@', 'gmail', '.com'],
    },
    {
      name: 'Johanna Kartila - Digital Program Director at Nortal',
      phone: ['050', '486', '7441'],
      email: ['jkartilamalmivaara', '@', 'gmail', '.com'],
    },
    {
      name: 'VALENTIN DZHANKHOTOV - Senior Frontend Developer at HIQ',
      phone: ['050', '464', '1690'],
      email: ['valentin', '@', 'lamia', '.fi'],
    },
  ];

  const reconstructEmail = (emailParts) => {
    return emailParts.join('');
  };

  const reconstructPhone = (phoneParts) => {
    return phoneParts.join('');
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
      <div className="max-w-4xl mx-auto my-10 p-8 bg-white rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">Contact Me</h1>
        <div className="text-center">
          <p className="text-lg text-gray-600 mb-4">
            <strong>Email:</strong>{' '}
            {showEmail === 'main' ? (
              <a href={`mailto:alqassab31@gmail.com`} className="text-blue-500 hover:underline">
                alqassab31@gmail.com
              </a>
            ) : (
              <button
                onClick={() => setShowEmail('main')}
                className="text-blue-500 hover:underline"
              >
                SHOW EMAIL
              </button>
            )}
          </p>
          <p className="text-lg text-gray-600 mb-4">
            <strong>Phone:</strong>{' '}
            {showPhone === 'main' ? (
              <a href={`tel:+358413195038`} className="text-blue-500 hover:underline">
                +358413195038
              </a>
            ) : (
              <button
                onClick={() => setShowPhone('main')}
                className="text-blue-500 hover:underline"
              >
                SHOW PHONE
              </button>
            )}
          </p>
          <a
            href="https://www.linkedin.com/in/bakr-alqassab/"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-blue-600 text-white py-2 px-4 rounded-full hover:bg-blue-700 transition-colors duration-300"
          >
            <i className="fab fa-linkedin mr-2"></i>
            Connect on LinkedIn
          </a>
        </div>

        <div className="mt-12">
          <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Recommendations</h2>
          {contacts.map((contact, i) => (
            <div key={i} className="mb-6 p-6 bg-gray-50 rounded-lg shadow-md">
              <p className="text-xl font-medium text-gray-700">{contact.name}</p>
              <p className="text-lg text-gray-600 mt-4">
                <strong>Phone:</strong>{' '}
                {showPhone === i ? (
                  <a
                    href={`tel:${reconstructPhone(contact.phone)}`}
                    className="text-blue-500 hover:underline"
                  >
                    {reconstructPhone(contact.phone)}
                  </a>
                ) : (
                  <button
                    onClick={() => setShowPhone(i)}
                    className="text-blue-500 hover:underline"
                  >
                    SHOW PHONE
                  </button>
                )}
              </p>
              <p className="text-lg text-gray-600 mt-2">
                <strong>Email:</strong>{' '}
                {showEmail === i ? (
                  <a
                    href={`mailto:${reconstructEmail(contact.email)}`}
                    className="text-blue-500 hover:underline"
                  >
                    {reconstructEmail(contact.email)}
                  </a>
                ) : (
                  <button
                    onClick={() => setShowEmail(i)}
                    className="text-blue-500 hover:underline"
                  >
                    SHOW EMAIL
                  </button>
                )}
              </p>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default ContactPage;
