import React from "react";
import { motion } from "framer-motion";
import Card from "../components/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

function Projects() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 3 }}
    >
      <section
        id="projects"
        class="min-h-screen flex flex-col items-center text-center bg-white  dark:bg-gray-900  dark:text-white"
      >
        <h2 class="text-4xl font-bold mt-12 mb-8 animate-slideIn">
          My Projects
        </h2>

        <div class="my-6">
          <button class="p-6 bg-red-600 text-white w-[500px] h-16 radius-rounded text-center mx-auto rounded-2xl flex items-center justify-center   border-2 ">
            <a href="https://github.com/BakrAlqassab" _blank className="">
              GitHub
            </a>
          </button>
        </div>
        {/* <hr className="border-gray-100 w-full my-2" /> */}
        <FontAwesomeIcon icon={faArrowUp} size="sm" className="my-2" />

        <div className="text-black dark:text-white">OR</div>
        <FontAwesomeIcon icon={faArrowDown} size="sm" className="my-2" />

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          <Card
            url="./images/carhub.png"
            title="Carhub Project"
            description="Online gallery for all types of cars with details"
            tags={["React", "TailwindCss", "Api"]}
            RepositoryButton={[
              {
                url: "https://github.com/BakrAlqassab/carhub_nextjs",
                title: "Repo",
              },
            ]}
            DemoButton={[
              { url: "https://carhub.bakralqassab.fi/", title: "Demo" },
            ]}
          />

          <Card
            url="./images/charts.png"
            title="Charts Sensors data project"
            description="Charts display the coming data from 3 sensors"
            tags={["Vue", "Chart.js", "Vuex", "Router", "TailwindCss", "UUID"]}
            RepositoryButton={[
              {
                url: "https://github.com/BakrAlqassab/sensors_data_charts_vue",
                title: "Repo",
              },
            ]}
            DemoButton={[
              { url: "https://charts.bakralqassab.fi/", title: "Demo" },
            ]}
          />

          <Card
            url="./images/new_FS_chart.png"
            title="Full stack Charts Sensors data project"
            description="Charts display the coming data from 3 sensors"
            tags={[
              "Vue",
              "Nuxt",
              "Vuetify",
              "ApexChart.js",
              "Vuex",
              "axios",
              "RestfulAPI",
              "Node.js",
              "MongoDB",
            ]}
            RepositoryButton={[
              {
                url: "https://github.com/BakrAlqassab/Dashboard_project_FE",
                title: "FE Repo",
              },   
               {
                url: "https://github.com/BakrAlqassab/Dashboard_project_BE",
                title: "BE Repo",
              },
            ]}
            DemoButton={[
              { url: "https://nextjsreactjsclone.netlify.app/en", title: "Demo" },
            ]}
          />

          <Card
            url="./images/Airbnb_Map.png"
            title="Airbnb clone Project"
            description="Online gallery for all types of cars with details"
            tags={[
              "React",
              "authentication",
              "Cloudinary",
              "swiper",
              "contentful",
              "MongoDB",
            ]}
            RepositoryButton={[
              {
                url: "https://github.com/BakrAlqassab/airbnb-clone-nextjs",
                title: "Repo",
              },
            ]}
            DemoButton={[
              {
                url: "https://nextjsreactjsclone.netlify.app/fi",
                title: "Demo",
              },
            ]}
          />

          <Card
            url="./images/Weather.png"
            title="Vue weather Project"
            description="Online gallery for all types of cars with details"
            tags={["Vuejs"]}
            RepositoryButton={[
              {
                url: "https://github.com/BakrAlqassab/vue_weather",
                title: "Repo",
              },
            ]}
          />

          <Card
            url="./images/placeHolder.png"
            title="Face Recognation"
            description="Online gallery for all types of cars with details"
            tags={["React", "Node.js", "axios", "authentication"]}
            RepositoryButton={[
              {
                url: "https://github.com/BakrAlqassab/facerecognization",
                title: "Repo",
              },
            ]}
          />

          <Card
            url="./images/BusinessAgency.png"
            title="Carhub Project"
            description="Online gallery for all types of cars with details"
            tags={["Css", "JS"]}
            RepositoryButton={[
              {
                url: "https://github.com/BakrAlqassab/Business-Agency",
                title: "Repo",
              },
            ]}
            DemoButton={[
              {
                url: "https://bakralqassab.github.io/Apple-eCommerce/",
                title: "Demo",
              },
            ]}
          />

          <Card
            url="./images/Apple_Project.png"
            title="Carhub Project"
            description="Online gallery for all types of cars with details"
            tags={["Css", "JS"]}
            RepositoryButton={[
              {
                url: "https://github.com/BakrAlqassab/Apple-eCommerce",
                title: "Repo",
              },
            ]}
            DemoButton={[
              {
                url: "https://bakralqassab.github.io/Apple-eCommerce/",
                title: "Demo",
              },
            ]}
          />
        </div>
      </section>
    </motion.div>
  );
}

export default Projects;
