import React from 'react';
import { motion } from 'framer-motion';

function Education() {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 3 }}>
      <div className="container mx-auto px-4 py-16 dark:text-white text-black dark:bg-gray-900 bg-white">
      <section id="education" class="min-h-screen dark:bg-gray-900 py-16">
    <div class="max-w-4xl mx-auto">
      <h2 class="text-4xl font-bold mb-8 text-center">Education</h2>
      <div class="mb-10">
        <h3 class="text-2xl font-semibold mb-2">HAMK University of Applied Sciences</h3>
        <p class="text-lg ">Hämeenlinna, 2021</p>
        <p class="text-md">Bachelor's Degree Programme in Business Information Technology</p>
        <ul class="list-disc pl-5">
          <li>Developed proficiency in diverse software engineering and programming methodologies, complemented by hands-on project completion.</li>
          <li>Fostered innovation and business acumen.</li>
        </ul>
      </div>
      <div class="mb-8">
        <h3 class="text-2xl font-semibold mb-2">Dijlah University</h3>
        <p class="text-lg ">Baghdad, 2014</p>
        <p class="text-md">Computer Science</p>
        <ul class="list-disc pl-5">
          <li>Computer management, coding, learning Visual Basic, C++, and C# deeply.</li>
        </ul>
      </div>
    </div>
  </section>
    
      </div>
    </motion.div>
  );
}

export default Education;
