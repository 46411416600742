import React from 'react';
import SkillLoader from '../components/SkillLoader';
import { motion } from 'framer-motion';

function Skills() {
  return (
    <div className="container mx-auto px-4 py-16 text-white   mt-6">
     <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>

      <section id="skills" class="min-h-screen flex flex-col items-center bg-white  text-black dark:bg-gray-900  dark:text-white  ">
 
      <h1 className="text-4xl font-bold mb-4"> My Skills</h1>

      <div class="max-w-4xl w-full">
        <h3 class="text-2xl font-semibold mb-4 text-yellow-300">Front-end Technologies</h3>
{/* bg-gray-900 */}
        <SkillLoader label="HTML5" level="100%" color="#F59E0B" />
        <SkillLoader label="JavaScript" level="100%" color="#F59E0B" />
        <SkillLoader label="Typescript" level="100%" color="#F59E0B" />
        <SkillLoader label="React" level="100%" color="#F59E0B" />
        <SkillLoader label="React Native" level="50%" color="#F59E0B" />
        <SkillLoader label="Vuejs" level="100%" color="#F59E0B" />
        <SkillLoader label="Unity3D" level="50%" color="#F59E0B" />
        <hr className="hr"/>
    


        {/* bg-green-500 */}

        <h3 class="text-2xl font-semibold mb-4 text-green-400">Back-end Technologies</h3>
        <SkillLoader label="Node.js" level="75%" color="#10B981" />
        <SkillLoader label="PHP" level="65%" color="#10B981" />
        <SkillLoader label="GoLang" level="45%" color="#10B981" />
        <SkillLoader label="Spring boot java" level="45%" color="#10B981" />
        <SkillLoader label="ASP.NET" level="45%" color="#10B981" />
        <SkillLoader label="REST API" level="100%" color="#10B981" />
        <SkillLoader label="GraphQL" level="100%" color="#10B981" />
        <SkillLoader label="Unity3D" level="50%" color="#10B981" />
   
        <hr className="hr"/>

        {/* bg-orange-400 */}
        <h3 class="text-2xl font-semibold mb-4 text-orange-400">CSS & design system implementation & Re-usable components library </h3>

        <SkillLoader label="CSS" level="100%" color="#FB923C" />
        <SkillLoader label="Tailwindcss" level="100%" color="#FB923C" />
        <SkillLoader label="Sass" level="70%" color="#FB923C" />
        <SkillLoader label="Material UI" level="45%" color="#FB923C" />
        <SkillLoader label="Bootstrap" level="60%" color="#FB923C" />
        <SkillLoader label="Tachyons" level="70%" color="#FB923C" />
        <SkillLoader label="Shadcn" level="40%" color="#FB923C" />
        <SkillLoader label="Unity3D" level="50%" color="#FB923C" />
        <hr className="hr"/>
    


        <h3 class="text-2xl font-semibold mb-4 text-teal-500">Design Technologies</h3>

        <SkillLoader label="Figma" level="80%" color="#14B8A6" />
        <SkillLoader label="Zeplin" level="70%" color="#14B8A6" />

        <hr className="hr"/>

        <h3 class="text-2xl font-semibold mb-4 text-red-400">Databases</h3>
        <SkillLoader label="SQL" level="70%" color="#EF5350" />
        <SkillLoader label="MongoDB" level="80%" color="#EF5350" />
        <hr className="hr"/>

        <h3 class="text-2xl font-semibold mb-4 text-blue-400">Other Techniques</h3>
        <SkillLoader label="Linux" level="100%" color="#3B82F6" />
        <SkillLoader label="Git" level="100%" color="#3B82F6" />
        <SkillLoader label="GCP" level="50%" color="#3B82F6" />
        <SkillLoader label="CMS( contetnful )" level="85%" color="#3B82F6" />
        <SkillLoader label="CMS ( Drupal )" level="60%" color="#3B82F6" />
        <SkillLoader label="Docker" level="40%" color="#3B82F6" />
        <SkillLoader label="Scrum" level="80%" color="#3B82F6" />

      </div>
    </section>
      </motion.div>
    </div>
    
  );
}

export default Skills;
