import React from 'react';
import { motion } from 'framer-motion';
// import Testimonials from "../components/Testimonials"

function Home() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 3 }}
    >
      <div className="container mx-auto px-4 py-16 dark:text-gray-300">
        <h1 className="text-2xl md:text-4xl font-bold mb-4 text-center">
          Welcome to My Portfolio
        </h1>
        <br/>
        <br/>
    
        <h1 className="text-4xl ">Hi!</h1>
        <br/>
        <p className=" text-xl md:text-4xl font-['math'] ">
          My name is Bakr Alqassab. I'm a Software Developer with a solid
          Five-years track record in the industry. I have robust innovative solutions for diverse sizes and types of projects, for Retail sports
          clothing, Big Tourist Resorts, a Big photography company that serves
          Finnish schools, etc
        </p>
        <br />
        <p className=" text-xl md:text-4xl  font-['math'] ">
          My fervent passion for problem solving is matched only by my
          commitment to continuous learning and skill enhancement, ensuring that
          I meet every challenge head-on with a solu on oriented mindset.
        </p>
        {/* <Testimonials/> */}
  
        <div className="flex justify-center items-center w-full my-12 ">
          {/* <div className="h-[180px] w-[180px]">
            <img
              src="./images/Portfolio_image.jpg"
              className=" rounded-[50%] "
              alt="Bakr Alqassab"
            />
          </div> */}
          <a
            href="BAKR_AL-QASSAB CV.pdf"
            download
            className=" text-lg h-[60px] font-medium hover:bg-yellow-300 hover:text-white p-4 rounded border-yellow-500 border-solid border-2 text-black dark:text-white dark:bg-gray-900 "
          >
            Download My Resume
          </a>
        </div>

      </div>
    </motion.div>
  );
}

export default Home;
