import React, { useEffect, useState } from 'react';

function SkillLoader({ label, level, color }) {
  const [width, setWidth] = useState('0%');

  useEffect(() => {
    // Animate the width to the desired level
    setTimeout(() => setWidth(level), 100); // Delay to ensure it animates after render
  }, [level]);

  return (
    <div className="mb-4">
      <div className="text-lg font-semibold mb-1">{label}</div>
      <div className="w-full h-4 bg-gray-700 rounded-full">
        <div
          className="h-full rounded-full"
          style={{
            width: width,
            backgroundColor: color,
            transition: 'width 2s ease-in-out',
          }}
        ></div>
      </div>
    </div>
  );
}

export default SkillLoader;
